import { MetricExprRule } from 'src/components/SparkplugMetricInput/SparkplugMetricInput.vue';
import { rules } from 'src/util/validator-rules';

/**
 * utility fn for validating a metric expression for the SparkplugDeviceWrite provider.
 * this exists so the config validation and vuetify rules work the same
 */
export const validateSparkplugDeviceWriteMetricExpr: MetricExprRule = expr => {
    return rules.sparkplugMetricExpr(expr, {
        dataset: false,
        meta: false,
        metric: false,
        propset: false,
        template: false,
    }) || true;
};

export const validateSparkplugMetricExpr: MetricExprRule = expr => {
    return rules.sparkplugMetricExpr(expr, {
        dataset: false,
        meta: true,
        metric: true,
        propset: {
            propset: true,
            propsetlist: true,
        },
        template: false,
    }) || true;
};
