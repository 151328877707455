import { z } from 'zod';
import { varConditionSchema } from './conditional.zod';
import { varpConfigBase } from './var-provider.zod';
import { varNameSchema } from '../var-providers/var.zod';

const customExecutionSchema = z.object({
    type: z.literal('custom'),
    condition: varConditionSchema,
});

export const scriptProviderAttrsSchema = z.object({
    scriptId: z.string(),
    executeCfg: customExecutionSchema,
});

export const scriptProviderSchema = varpConfigBase.extend({
    type: z.literal('script'),
    attrs: scriptProviderAttrsSchema,
    inputs: z.array(varNameSchema),
});
