import { CreateMaterialData } from '@redviking/argonaut-util/types/material-create-data';
import { messages } from 'src/i18n';
import { Notify } from 'src/notifications';
import { SerialNumFmt } from 'src/serial-numbers/serial-number.entity';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { AddNewMaterialDocument, ArgoMaterialAttributeInsertInput, GetMaterialQuery, InsertMaterialAttributeDocument, MaterialFragment, UpdateMaterialDocument, UpdateMaterialMutation } from 'types/db';
import { SaveResult } from 'types';
import { validateMaterial } from './material.validations';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
} from 'src/components/EntityDetail';
import { type AttributeSettingForAttributeLinker } from '@redviking/argonaut-util/types/attribute-settings/attribute-settings-config.zod';

export type MaterialEntity = Pick<GetMaterialQuery['material'][number],
    'id' |
    'enabled' |
    'serial_number' |
    'materialAttributes'
> & {
    created_at?: string;
    labelPrint: boolean;
    snInputValues?: string[];
    materialClass: {
        attributeSettings: AttributeSettingForAttributeLinker[];
        id: MaterialFragment['material_model']['material_class']['id'];
        udfSchemas: GetMaterialQuery['material'][number]['material_model']['material_class_udf_schemas'];
    };
    materialModel: Pick<GetMaterialQuery['material'][number]['material_model'],
        'id' |
        'sn_format'
    > & {
        attributeSettings: AttributeSettingForAttributeLinker[];
    };
    udfData: Pick<MaterialFragment['udfData'][number],
        'id' |
        'num_val' |
        'str_val' |
        'udf_column_id'
    >[];
    ignoreSchemaWarning: boolean;
};

export const saveMaterialEntity = async (payload: ExtendedEntityParameters<'material'>): Promise<void | SaveResult> => {
    const {
        entity,
        oldEntity: originalEntity,
    } = payload;
    if (!entity.serial_number || entity.serial_number.length <= 0) {
        const error = new Error('Material missing "serial_number" property');
        Notify.error(error.message, error);
        throw error;
    }
    const validateResult = await validateMaterial(payload);

    if (validateResult) {
        if (validateResult.status === 'error' && validateResult.errorMessage) {
            Notify.error(validateResult.errorMessage);
        }
        return validateResult;
    }

    const insert: ArgoMaterialAttributeInsertInput[] = entity.materialAttributes.filter(attr => !originalEntity?.materialAttributes.find(a => a.attribute.id === attr.attribute.id)).map(a => ({
        material_id: entity.id,
        attribute_id: a.attribute.id,
    }));
    const deleteIds = originalEntity?.materialAttributes.filter(attr => !entity.materialAttributes.find(a => a.attribute.id === attr.attribute.id)).map(a => a.attribute.id) || [];

    const result: UpdateMaterialMutation = await gqlClient.request({
        document: UpdateMaterialDocument,
        variables: {
            attrsToAdd: insert,
            materialId: entity.id,
            attrsToDelete: deleteIds,
            materialUdfs: entity.udfData.map(udfDataInstance => ({
                ...udfDataInstance,
                material_id: entity.id,
            })),
        },
    });
    if (
        (result.insert_argo_material_attribute?.affected_rows && insert.length === result.insert_argo_material_attribute?.affected_rows) &&
        (result.delete_argo_material_attribute?.affected_rows && deleteIds.length === result.delete_argo_material_attribute?.affected_rows)
    ) {
        Notify.win(messages.material.material.saved);
        return {
            status: 'success',
            newEntity: {
                ...entity,
                ignoreSchemaWarning: false,
            },
        };
    }
};

export async function createMaterialEntity (payload: EntityModifyParameters<'material'>): Promise<void | SaveResult> {
    const { entity } = payload;
    try {
        const validateResult = await validateMaterial(payload);

        if (validateResult) {
            if (validateResult.status === 'error' && validateResult.errorMessage) {
                Notify.error(validateResult.errorMessage);
            }
            return validateResult;
        }
        const matData: CreateMaterialData = {
            locationId: null,
            materialModelId: entity.materialModel.id,
            serialNumberCfg: entity.materialModel?.sn_format
                ? {
                    kind: 'fmt',
                    tz: new Intl.DateTimeFormat().resolvedOptions().timeZone,
                    snfInputs: (entity.materialModel?.sn_format?.config as SerialNumFmt['config'] || { tokens: [] }).tokens
                        .filter(e => e.type === 'input')
                        .reduce((acc, cur, i) => {
                            const val = entity.snInputValues![i];
                            if (!val) {
                                throw new Error(`Value required for ${cur.name}`);
                            }
                            acc[cur.name] = entity.snInputValues![i];
                            return acc;
                        }, {} as Record<string, string>),
                }
                : {
                    kind: 'direct',
                    serialNumber: entity.serial_number,
                },
            labelPrint: entity.labelPrint === true,
            udfs: entity.materialClass.udfSchemas.reduce((acc, cur) => {
                const deeta = entity.udfData.find(e => e.udf_column_id === cur.udf_column!.id);
                if (deeta) {
                    acc[cur.udf_column!.id] = deeta.num_val ?? deeta.str_val!;
                }
                return acc;
            }, {} as Record<string, string | number>),
        };
        const { materialInsertMutation } = await gqlClient.request({
            document: AddNewMaterialDocument,
            variables: {
                material: {
                    debug: null,
                    material_data: matData,
                },
            },
        });
        const materialId = materialInsertMutation?.id;
        if (!materialId) {
            const errMsg = 'Material id not returned';
            Notify.error(errMsg);
            throw new Error(errMsg);
        }
        Notify.win(messages.material.material.saved);

        if (entity.materialAttributes.length !== 0) {
            const attributesToInsert: ArgoMaterialAttributeInsertInput[] = entity.materialAttributes.map(a => ({
                attribute_id: a.attribute.id,
                material_id: materialId,
            }));
            gqlClient.request({
                document: InsertMaterialAttributeDocument,
                variables: {
                    materialAttributes: attributesToInsert,
                },
            }).then(data => {
                Notify.win(`Linked ${data.insert_argo_material_attribute?.affected_rows || 0} attributes to material`);
            });
        }

        return {
            status: 'success',
            route: { params: { materialId } },
            newEntity: {
                ...entity,
                id: materialId,
                ignoreSchemaWarning: false,
            },
        };
    } catch (error) {
        Notify.error(error);
        throw error;
    }
}
