import { z } from 'zod';
import { optionalVarNameSchema } from '../var-providers/var.zod';
import { varOrConstSchema, varpConfigBase } from './var-provider.zod';
import { varConditionSchema } from './conditional.zod';
import { strVarWithMinLengthSchema } from './var.zod';

export const tcpClientProviderSchema = varpConfigBase.extend({
    type: z.literal('tcpClient'),
    attrs: z.object({
        serverHost: z.string().min(1),
        serverPort: z.number().min(1),
        messages: z.array(z.object({
            content: varOrConstSchema(strVarWithMinLengthSchema(1)),
            condition: varConditionSchema.refine(val => {
                return val.comparisons.length > 0 || val.evalTrigger === 'event';
            }, { message: 'condition must have at least one comparison' }),
        })),
        terminator: z.union([
            z.literal('none'),
            z.literal('crlf'),
            z.literal('lf'),
            z.literal('cr'),
        ]).default('crlf'),
        varForReceivedData: optionalVarNameSchema,
        treatDisconnectAsError: z.boolean().default(true),
    }),
});
