/* eslint-disable max-depth */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { accessor } from 'src/store';
import { ChangeLog } from 'src/components/EntityDetail';
import { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { getCellName, getCellType, getMacroPreviousItemData, getScreenPreviousItemData, getScreenUpdateMessages, getVarpPreviousItemData } from './util/applet-design-util';
import isEqual from 'lodash.isequal';
import { AppletChangeLogExtraData } from './applet.entity';
import varpTypeMap from './varps/varpTypeMap';
import macroTypeMap from './macros/macroTypeMap';
import cellTypeMap from '@redviking/argonaut-core-ui/src/applet/design/cells/cellTypeMap';
import { generateInterpolateForOneOutput, generateInterpolatesForMultipleOutputs } from './util/changeLogUtil';

type ScriptOrigin = {
    type: 'cell',
    screenId: string,
    cell: Latest.Screen.Cells.AppletCell,
} | {
    type: 'varp',
    varp: Latest.VarProviders.VarProvider,
};

type BaseTypedPreviousChangeLog = {
    type: AppletChangeLogExtraData['type'];
    changeLogItem: ChangeLog.IncludePreviousChangeLogData;
}

interface ScreenChangeLog extends BaseTypedPreviousChangeLog {
    type: 'screen';
    changeLogItem: ChangeLog.IncludePreviousChangeLogData<PreviousScreenItemData>;
}

interface VarpChangeLog extends BaseTypedPreviousChangeLog {
    type: 'varps';
    changeLogItem: ChangeLog.IncludePreviousChangeLogData<PreviousVarpItemData>;
}

interface MacroChangeLog extends BaseTypedPreviousChangeLog {
    type: 'macros';
    changeLogItem: ChangeLog.IncludePreviousChangeLogData<PreviousMacroItemData>;
}

interface ScriptChangeLog extends BaseTypedPreviousChangeLog {
    type: 'scripts';
    changeLogItem: ChangeLog.IncludePreviousChangeLogData<PreviousScriptItemData>;
}

interface SparkplugDeviceMetricsChangeLog extends BaseTypedPreviousChangeLog {
    type: 'sparkplugDeviceMetrics';
    changeLogItem: ChangeLog.IncludePreviousChangeLogData<Latest.SparkplugDeviceMetric>;
}

export type TypedPreviousChangeLog = ScreenChangeLog | VarpChangeLog | MacroChangeLog | ScriptChangeLog | SparkplugDeviceMetricsChangeLog;

// type ScriptMessage = {
//     type: 'script';
//     oldScriptValue?: string;
//     currentScriptValue?: string;
// };

const generateScriptChangeLog = (payload: {
    id: string;
    action: 'update'
    currentScriptValue: string;
    originalScriptValue: Latest.RuntimeScriptCfg;
} | {
    id: string;
    action: 'add';
    currentScriptValue: string;
}): ChangeLog.ChangeLogChange<{
    origin: ScriptOrigin;
    scriptCfg: Latest.RuntimeScriptCfg;
}> => {
    const scriptOrigin = accessor.appletDesign.findScriptOrigin(payload.id, payload.action === 'update');
    if (!scriptOrigin) {
        throw new Error('Could not find script origin');
    }
    const title: ChangeLog.Interpolate = {
        text: '',
        type: 'interpolate',
        data: [
            {
                type: 'code',
                name: 'itemType',
                value: scriptOrigin.type === 'cell' ? cellTypeMap[scriptOrigin.cell.type].text : varpTypeMap[scriptOrigin.varp.type].text,
            },
            {
                type: 'code',
                name: 'itemName',
                value: scriptOrigin.type === 'cell' ? getCellName(scriptOrigin.cell) : scriptOrigin.varp.label || 'Unnamed Provider',
            },
        ],
    };

    let messages: ChangeLog.MessageType[] = [];

    if (payload.action === 'add') {
        title.text = 'A script was added to {itemType} {itemName}. It contains the following content:';
        return {
            title,
            itemId: payload.id,
            action: payload.action,
            messages: [
                {
                    type: 'script',
                    oldScriptValue: undefined,
                    currentScriptValue: payload.currentScriptValue,
                },
            ],
        };
    } else if (payload.action === 'update') {
        title.text = 'A script was updated for {itemType} {itemName} with the following changes:';
        messages = [
            {
                type: 'script',
                oldScriptValue: payload.originalScriptValue.script,
                currentScriptValue: payload.currentScriptValue,
            },
        ];
    }
    return {
        title,
        messages,
        itemId: payload.id,
        action: payload.action,
        previousItem: {
            origin: scriptOrigin,
            scriptCfg: payload.originalScriptValue,
        },
    };
};

const generateVarpSpecificChangeLog = <T extends Latest.VarProviders.VarProvider>(original: T, edited: T): ChangeLog.MessageType[] => {
    const ret: ChangeLog.MessageType[] = [];
    switch (edited.type) {
        case 'formatted': {
            if (original.type !== 'formatted') {
                return [];
            }
            if (edited.attrs.stringTemplate !== original.attrs.stringTemplate) {
                ret.push({
                    type: 'interpolate',
                    text: 'Changed template from {oldTemplate} to {newTemplate}.',
                    data: [
                        {
                            name: 'oldTemplate',
                            type: 'code',
                            value: original.attrs.stringTemplate,
                        },
                        {
                            name: 'newTemplate',
                            type: 'code',
                            value: edited.attrs.stringTemplate,
                        },
                    ],
                });
            }
            if (edited.attrs.outputVar !== original.attrs.outputVar) {
                ret.push(generateInterpolateForOneOutput(original.attrs.outputVar, edited.attrs.outputVar));
            }
            return ret;
        }
        case 'local': {
            if (original.type !== 'local') {
                return [];
            }
            const outputVariableChanges = generateInterpolatesForMultipleOutputs(original.outputs, edited.outputs);
            if (outputVariableChanges.length) {
                ret.push(...outputVariableChanges);
            }
            return ret;
        }
        case 'script': {
            if (original.type !== 'script') {
                return [];
            }
            ret.push({
                type: 'interpolate',
                text: 'changed execution type from {oldType} to {newType}.',
                data: [
                    {
                        name: 'oldType',
                        type: 'code',
                        value: original.attrs.executeCfg.type,
                    },
                    {
                        name: 'newType',
                        type: 'code',
                        value: edited.attrs.executeCfg.type,
                    },
                ],
            });
            return ret;
        }
        case 'timer': {
            if (original.type !== 'timer') {
                return [];
            }
            if (edited.attrs.dir !== original.attrs.dir) {
                ret.push({
                    type: 'interpolate',
                    text: 'changed from {oldDir} to {newDir}.',
                    data: [
                        {
                            type: 'code',
                            name: 'oldDir',
                            value: original.attrs.dir === 'up' ? 'Stopwatch' : 'Timer',
                        },
                        {
                            type: 'code',
                            name: 'newDir',
                            value: edited.attrs.dir === 'up' ? 'Stopwatch' : 'Timer',
                        },
                    ],
                });
            }
            if (!isEqual(edited.attrs.maxVal, original.attrs.maxVal)) {
                ret.push({
                    type: 'interpolate',
                    text: 'changed max from {oldMax} to {newMax}.',
                    data: [
                        {
                            type: 'code',
                            name: 'oldMax',
                            value: original.attrs.maxVal.type === 'const'
                                ? original.attrs.maxVal.val?.toString() || 'N/A'
                                : `Variable Reference: ${original.attrs.maxVal.var}`,
                        },
                        {
                            type: 'code',
                            name: 'newMax',
                            value: edited.attrs.maxVal.type === 'const'
                            ? edited.attrs.maxVal.val?.toString() || 'N/A'
                            : `Variable Reference: ${edited.attrs.maxVal.var}`,
                        },
                    ],
                });
            }
            if (!isEqual(edited.attrs.minVal, original.attrs.minVal)) {
                ret.push({
                    type: 'interpolate',
                    text: 'changed min from {oldMin} to {newMin}.',
                    data: [
                        {
                            type: 'code',
                            name: 'oldMin',
                            value: original.attrs.minVal.type === 'const'
                                ? original.attrs.minVal.val?.toString() || 'N/A'
                                : `Variable Reference: ${original.attrs.minVal.var}`,
                        },
                        {
                            type: 'code',
                            name: 'newMin',
                            value: edited.attrs.minVal.type === 'const'
                            ? edited.attrs.minVal.val?.toString() || 'N/A'
                            : `Variable Reference: ${edited.attrs.minVal.var}`,
                        },
                    ],
                });
            }
            if (edited.attrs.outputVar !== original.attrs.outputVar) {
                ret.push(generateInterpolateForOneOutput(original.attrs.outputVar, edited.attrs.outputVar));
            }
            return ret;
        }
        default:
            return [];
    }
};

export type PreviousScreenItemData = {
    screen: Latest.Screen.Config;
    scripts: Latest.RuntimeScriptCfg[];
    macros: Latest.Macros.AppletMacro[];
    varps: Latest.VarProviders.VarProvider[];
};

export type PreviousVarpItemData = {
    script?: Latest.RuntimeScriptCfg;
    varp: Latest.VarProviders.VarProvider;
    sparkplugMetrics: Latest.SparkplugDeviceMetric[];
}

export type PreviousMacroItemData = {
    macroCfg: Latest.Macros.AppletMacro;
    varp: Latest.VarProviders.VarProvider;
    cellData: {
        screenId: string;
        screenIdx: number;
        cellCfgs: {
            cellIdx: number;
            cellCfg: Latest.Screen.Cells.AppletCell;
        }[];
    };
}

export type PreviousScriptItemData = {
    origin: ScriptOrigin;
    scriptCfg: Latest.RuntimeScriptCfg;
};

const getScreenChanges = (originalConfig: Latest.AppletDesignVersionConfig, newConfig: Latest.AppletDesignVersionConfig): ChangeLog.ChangeLogChange<PreviousScreenItemData>[] => {
    const ret: ChangeLog.ChangeLogChange<PreviousScreenItemData>[] = [];
    const newScreens = newConfig.screens;
    const originalScreens = originalConfig.screens;
    for (const screen of newScreens) {
        const originalScreen = originalScreens.find(s => s.id === screen.id);
        if (originalScreen && !isEqual(originalScreen, screen)) {
            ret.push({
                action: 'update',
                itemId: screen.id,
                previousItem: getScreenPreviousItemData(originalConfig, originalScreen),
                title: {
                    type: 'interpolate',
                    text: 'Screen {screenName} was updated',
                    data: [
                        {
                            type: 'code',
                            name: 'screenName',
                            value: screen.name,
                        },
                    ],
                },
                messages: getScreenUpdateMessages({
                    originalScreen,
                    newScreen: screen,
                }),
            });
        } else if (!originalScreen) {
            ret.push({
                messages: [],
                action: 'add',
                itemId: screen.id,
                title: {
                    type: 'interpolate',
                    text: 'Screen {screenName} was added',
                    data: [
                        {
                            type: 'code',
                            name: 'screenName',
                            value: screen.name,
                        },
                    ],
                },
            });
        }
    }

    for (const oldScreen of originalScreens) {
        if (!newScreens.find(s => s.id === oldScreen.id)) {
            ret.push({
                action: 'delete',
                itemId: oldScreen.id,
                previousItem: getScreenPreviousItemData(originalConfig, oldScreen),
                messages: oldScreen.cells.map<ChangeLog.MessageType>(cell => ({
                    messageId: cell.id,
                    type: 'interpolate',
                    text: 'A{cellType}Argonaut cell named{cellName}was removed',
                    data: [
                        {
                            type: 'code',
                            name: 'cellType',
                            value: getCellType(cell),
                        },
                        {
                            type: 'code',
                            name: 'cellName',
                            value: getCellName(cell),
                        },
                    ],
                })),
                title: {
                    type: 'interpolate',
                    text: 'Screen {screenName} was removed',
                    data: [
                        {
                            type: 'code',
                            name: 'screenName',
                            value: oldScreen.name,
                        },
                    ],
                },
            });
        }
    }
    return ret;
};

const getVarpChanges = (originalConfig: Latest.AppletDesignVersionConfig, newConfig: Latest.AppletDesignVersionConfig): ChangeLog.ChangeLogChange<PreviousVarpItemData>[] => {
    const ret: ChangeLog.ChangeLogChange<PreviousVarpItemData>[] = [];
    const newVarps = newConfig.varProviders;
    const originalVarps = originalConfig.varProviders;
    for (const varp of newVarps) {
        const originalVarp = originalVarps.find(vp => vp.id === varp.id);
        if (originalVarp && !isEqual(originalVarp, varp)) {
            ret.push({
                itemId: varp.id,
                action: 'update',
                messages: generateVarpSpecificChangeLog(originalVarp, varp),
                previousItem: getVarpPreviousItemData(originalConfig, originalVarp),
                title: {
                    type: 'interpolate',
                    text: 'The following changes were made to the {varpType} Variable Provider {varpName}:',
                    data: [
                        {
                            type: 'code',
                            name: 'varpType',
                            value: varpTypeMap[varp.type].text,
                        },
                        {
                            type: 'code',
                            name: 'varpName',
                            value: varp.label || 'Unnamed Provider',
                        },
                    ],
                },
            });
        } else if (!originalVarp) {
            ret.push({
                messages: [],
                action: 'add',
                itemId: varp.id,
                title: {
                    type: 'interpolate',
                    text: 'A {varpType} Variable Provider {varpName} was added',
                    data: [
                        {
                            type: 'code',
                            name: 'varpType',
                            value: varpTypeMap[varp.type].text,
                        },
                        {
                            type: 'code',
                            name: 'varpName',
                            value: varp.label || 'Unnamed Provider',
                        },
                    ],
                },
            });
        }
    }

    for (const oldVarp of originalVarps.filter(vp => vp.type !== 'macroTarget')) {
        if (!newVarps.find(vp => vp.id === oldVarp.id)) {
            ret.push({
                itemId: oldVarp.id,
                action: 'delete',
                previousItem: getVarpPreviousItemData(originalConfig, oldVarp),
                title: {
                    type: 'interpolate',
                    text: 'A {varpType} Variable Provider {varpName} was removed',
                    data: [
                        {
                            type: 'code',
                            name: 'varpType',
                            value: varpTypeMap[oldVarp.type].text,
                        },
                        {
                            type: 'code',
                            name: 'varpName',
                            value: oldVarp.label || 'Unnamed Provider',
                        },
                    ],
                },
                messages: oldVarp.outputs.map<ChangeLog.Interpolate>(output => ({
                    type: 'interpolate',
                    text: '{outputVar}',
                    data: [
                        {
                            type: 'code',
                            name: 'outputVar',
                            value: output,
                        },
                    ],
                })),
            });
        }
    }
    return ret;

};

const getMacroChanges = (originalConfig: Latest.AppletDesignVersionConfig, newConfig: Latest.AppletDesignVersionConfig): ChangeLog.ChangeLogChange<PreviousMacroItemData>[] => {
    const ret: ChangeLog.ChangeLogChange<PreviousMacroItemData>[] = [];
    for (const macro of newConfig.macros) {
        const originalMacro = originalConfig.macros.find(m => m.id === macro.id);
        if (originalMacro && !isEqual(originalMacro, macro)) {
            ret.push({
                messages: [],
                action: 'update',
                itemId: macro.id,
                previousItem: getMacroPreviousItemData(originalConfig, originalMacro),
                title: {
                    type: 'interpolate',
                    text: 'A {macroType} Macro {macroName} was updated',
                    data: [
                        {
                            type: 'code',
                            name: 'macroType',
                            value: macroTypeMap[originalMacro.type].text,
                        },
                        {
                            type: 'code',
                            name: 'macroName',
                            value: originalMacro.name || 'Unnamed Macro',
                        },
                    ],
                },
            });
        } else if (!originalMacro) {
            ret.push({
                messages: [],
                action: 'add',
                itemId: macro.id,
                title: {
                    type: 'interpolate',
                    text: 'A {macroType} Macro {macroName} was added',
                    data: [
                        {
                            type: 'code',
                            name: 'macroType',
                            value: macroTypeMap[macro.type].text,
                        },
                        {
                            type: 'code',
                            name: 'macroName',
                            value: macro.name || 'Unnamed Macro',
                        },
                    ],
                },
            });
        }
    }
    for (const oldMacro of originalConfig.macros) {
        if (!newConfig.macros.find(m => m.id === oldMacro.id)) {
            const oldScreenId = originalConfig.screens.find(s => s.cells.find(c => c.type === 'macroTarget' && c.attrs.macroId === oldMacro.id))?.id;
            if (!oldScreenId) {
                throw new Error('Could not find screen that contained macro');
            }
            const screenThatContainedMacro = newConfig.screens.find(s => s.id === oldScreenId);
            if (screenThatContainedMacro) {
                ret.push({
                    messages: [],
                    action: 'delete',
                    itemId: oldMacro.id,
                    previousItem: getMacroPreviousItemData(originalConfig, oldMacro),
                    title: {
                        type: 'interpolate',
                        text: 'A {macroType} Macro {macroName} was removed',
                        data: [
                            {
                                type: 'code',
                                name: 'macroType',
                                value: macroTypeMap[oldMacro.type].text,
                            },
                            {
                                type: 'code',
                                name: 'macroName',
                                value: oldMacro.name || 'Unnamed Macro',
                            },
                        ],
                    },
                });
            } else {
                ret.push({
                    messages: [],
                    action: 'cascade',
                    itemId: oldMacro.id,
                    title: {
                        type: 'interpolate',
                        text: 'A {macroType} Macro {macroName} was removed because the containing screen was removed',
                        data: [
                            {
                                type: 'code',
                                name: 'macroType',
                                value: macroTypeMap[oldMacro.type].text,
                            },
                            {
                                type: 'code',
                                name: 'macroName',
                                value: oldMacro.name || 'Unnamed Macro',
                            },
                        ],
                    },
                });
            }
        }
    }

    return ret;
};

const getScriptChanges = (originalConfig: Latest.AppletDesignVersionConfig, newConfig: Latest.AppletDesignVersionConfig): ChangeLog.ChangeLogChange<{
    origin: ScriptOrigin;
    scriptCfg: Latest.RuntimeScriptCfg;
}>[] => {
    const ret: ChangeLog.ChangeLogChange<{
        origin: ScriptOrigin;
        scriptCfg: Latest.RuntimeScriptCfg;
    }>[] = [];
    for (const script of newConfig.scripts) {
        const originalScript = originalConfig.scripts.find(s => s.id === script.id);
        if (originalScript && !isEqual(originalScript, script)) {
            ret.push(generateScriptChangeLog({
                action: 'update',
                id: script.id,
                currentScriptValue: script.script,
                originalScriptValue: originalScript,
            }));
        } else if (!originalScript) {
            ret.push(generateScriptChangeLog({
                action: 'add',
                id: script.id,
                currentScriptValue: script.script,
            }));
        }
    }
    for (const oldScript of originalConfig.scripts) {
        if (!newConfig.scripts.find(s => s.id === oldScript.id)) {
            const scriptOrigin = accessor.appletDesign.findScriptOrigin(oldScript.id, true);
            if (!scriptOrigin) {
                throw new Error('Could not find script origin');
            }
            if (scriptOrigin.type === 'cell') {
                const screenThatContainedScript = newConfig.screens.find(s => s.id === scriptOrigin.screenId);
                if (screenThatContainedScript) {
                    const cellThatContainedScript = accessor.appletDesign.findCellInCfg(c => c.id === scriptOrigin.cell.id);
                    if (cellThatContainedScript) {
                        ret.push({
                            messages: [
                                {
                                    type: 'script',
                                    oldScriptValue: oldScript.script,
                                    currentScriptValue: undefined,
                                },
                            ],
                            action: 'delete',
                            itemId: oldScript.id,
                            previousItem: {
                                origin: scriptOrigin,
                                scriptCfg: oldScript,
                            },
                            title: {
                                type: 'interpolate',
                                text: 'A script was removed from {itemType} {itemName}. It contained the following content:',
                                data: [
                                    {
                                        type: 'code',
                                        name: 'itemType',
                                        value: cellTypeMap[scriptOrigin.cell.type].text,
                                    },
                                    {
                                        type: 'code',
                                        name: 'itemName',
                                        value: getCellName(scriptOrigin.cell),
                                    },
                                ],
                            },
                        });
                    } else {
                        ret.push({
                            messages: [
                                {
                                    type: 'script',
                                    oldScriptValue: oldScript.script,
                                    currentScriptValue: undefined,
                                },
                            ],
                            action: 'cascade',
                            itemId: oldScript.id,
                            title: {
                                type: 'interpolate',
                                text: 'A script was removed from {itemType} {itemName} because the containing cell was removed. It contained the following content:',
                                data: [
                                    {
                                        type: 'code',
                                        name: 'itemType',
                                        value: cellTypeMap[scriptOrigin.cell.type].text,
                                    },
                                    {
                                        type: 'code',
                                        name: 'itemName',
                                        value: getCellName(scriptOrigin.cell),
                                    },
                                ],
                            },
                        });
                    }
                } else {
                    ret.push({
                        messages: [
                            {
                                type: 'script',
                                oldScriptValue: oldScript.script,
                                currentScriptValue: undefined,
                            },
                        ],
                        action: 'cascade',
                        itemId: oldScript.id,
                        title: {
                            type: 'interpolate',
                            text: 'A script was removed from {itemType} {itemName} because the containing screen was removed. It contained the following content:',
                            data: [
                                {
                                    type: 'code',
                                    name: 'itemType',
                                    value: cellTypeMap[scriptOrigin.cell.type].text,
                                },
                                {
                                    type: 'code',
                                    name: 'itemName',
                                    value: getCellName(scriptOrigin.cell),
                                },
                            ],
                        },
                    });
                }
            } else if (scriptOrigin.type === 'varp') {
                const varpThatContainedScript = newConfig.varProviders.find(vp => vp.id === scriptOrigin.varp.id);
                if (varpThatContainedScript) {
                    ret.push({
                        messages: [
                            {
                                type: 'script',
                                oldScriptValue: oldScript.script,
                                currentScriptValue: undefined,
                            },
                        ],
                        action: 'delete',
                        itemId: oldScript.id,
                        previousItem: {
                            origin: scriptOrigin,
                            scriptCfg: oldScript,
                        },
                        title: {
                            type: 'interpolate',
                            text: 'A script was removed from {itemType} {itemName}. It contained the following content:',
                            data: [
                                {
                                    type: 'code',
                                    name: 'itemType',
                                    value: varpTypeMap[scriptOrigin.varp.type].text,
                                },
                                {
                                    type: 'code',
                                    name: 'itemName',
                                    value: scriptOrigin.varp.label || 'Unnamed Provider',
                                },
                            ],
                        },
                    });
                } else {
                    ret.push({
                        messages: [
                            {
                                type: 'script',
                                oldScriptValue: oldScript.script,
                                currentScriptValue: undefined,
                            },
                        ],
                        action: 'cascade',
                        itemId: oldScript.id,
                        title: {
                            type: 'interpolate',
                            text: 'A script was removed from {itemType} {itemName} because the containing variable provider was removed. It contained the following content:',
                            data: [
                                {
                                    type: 'code',
                                    name: 'itemType',
                                    value: varpTypeMap[scriptOrigin.varp.type].text,
                                },
                                {
                                    type: 'code',
                                    name: 'itemName',
                                    value: scriptOrigin.varp.label || 'Unnamed Provider',
                                },
                            ],
                        },
                    });
                }
            }
        }
    }
    return ret;
};

const getSparkplugDeviceMetricsChanges = (originalConfig: Latest.AppletDesignVersionConfig, newConfig: Latest.AppletDesignVersionConfig): ChangeLog.ChangeLogChange<Latest.SparkplugDeviceMetric>[] => {
    const ret: ChangeLog.ChangeLogChange<Latest.SparkplugDeviceMetric>[] = [];
    for (const spdm of newConfig.sparkplugDeviceMetrics) {
        const originalSpdm = originalConfig.sparkplugDeviceMetrics.find(s => s.name === spdm.name);
        if (originalSpdm && !isEqual(originalSpdm, spdm)) {
            ret.push({
                messages: [],
                action: 'update',
                itemId: spdm.name,
                previousItem: originalSpdm,
                title: {
                    type: 'interpolate',
                    text: 'The sparkplug metric {varName} was is {writable}.',
                    data: [
                        {
                            type: 'code',
                            name: 'varName',
                            value: spdm.name,
                        },
                        {
                            type: 'b',
                            name: 'writable',
                            value: spdm.permissions.write ? 'now writable' : 'no longer writable',
                        },
                    ],
                },
            });
        } else if (!originalSpdm) {
            ret.push({
                action: 'add',
                messages: [],
                itemId: spdm.name,
                title: {
                    type: 'interpolate',
                    text: 'The variable {varName} is now being tracked as a sparkplug metric and is {writable}.',
                    data: [
                        {
                            type: 'code',
                            name: 'varName',
                            value: spdm.name,
                        },
                        {
                            type: 'b',
                            name: 'writable',
                            value: spdm.permissions.write ? ' writable' : ' not writable',
                        },
                    ],
                },
            });
        }
    }
    for (const oldSpdm of originalConfig.sparkplugDeviceMetrics) {
        if (!newConfig.sparkplugDeviceMetrics.find(s => s.name === oldSpdm.name)) {
            const originalVarp = newConfig.varProviders.find(vp => vp.outputs.includes(oldSpdm.name));
            if (originalVarp) {
                ret.push({
                    messages: [],
                    action: 'delete',
                    itemId: oldSpdm.name,
                    previousItem: oldSpdm,
                    title: {
                        type: 'interpolate',
                        text: 'The variable {varName} is no longer being tracked as a sparkplug metric.',
                        data: [
                            {
                                type: 'code',
                                name: 'varName',
                                value: oldSpdm.name,
                            },
                        ],
                    },
                });
            } else {
                ret.push({
                    messages: [],
                    action: 'cascade',
                    itemId: oldSpdm.name,
                    title: {
                        type: 'interpolate',
                        text: 'The variable {varName} is no longer being tracked as a sparkplug metric becuase the containing variable provider was deleted.',
                        data: [
                            {
                                type: 'code',
                                name: 'varName',
                                value: oldSpdm.name,
                            },
                        ],
                    },
                });
            }
        }
    }
    return [];
};

export const getAppletDiffereneces = (): ChangeLog.ChangeLogCategory<AppletChangeLogExtraData>[] => {
    const entityData = accessor.entityAsType('appletDesignVersion');
    const originalEntity = accessor.originalEntityAsType('appletDesignVersion');
    if (!originalEntity || !entityData) {
        return [];
    }

    const ret: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData>[] = [];

    const screenChangeLog: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData> = {
        name: 'Screens',
        icon: 'mdi-monitor-dashboard',
        changes: getScreenChanges(originalEntity.config, entityData.config),
        extraData: {
            type: 'screen',
        },
    };
    if (screenChangeLog.changes.length) {
        ret.push(screenChangeLog);
    }

    const varpChangeLog: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData> = {
        name: 'Variable Providers',
        icon: 'mdi-variable',
        changes: getVarpChanges(originalEntity.config, entityData.config),
        extraData: {
            type: 'varps',
        },
    };
    if (varpChangeLog.changes.length) {
        ret.push(varpChangeLog);
    }

    const macroChangeLog: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData> = {
        name: 'Macros',
        icon: 'mdi-multiplication',
        changes: getMacroChanges(originalEntity.config, entityData.config),
        extraData: {
            type: 'macros',
        },
    };
    if (macroChangeLog.changes.length) {
        ret.push(macroChangeLog);
    }

    const scriptChangeLog: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData> = {
        name: 'Scripts',
        icon: 'mdi-script-text',
        changes: getScriptChanges(originalEntity.config, entityData.config),
        extraData: {
            type: 'scripts',
        },
    };
    if (scriptChangeLog.changes.length) {
        ret.push(scriptChangeLog);
    }

    const sparkplugDeviceMetricsChangeLog: ChangeLog.ChangeLogCategory<AppletChangeLogExtraData> = {
        name: 'Sparkplug Device Metrics',
        icon: 'mdi-flash',
        changes: getSparkplugDeviceMetricsChanges(originalEntity.config, entityData.config),
        extraData: {
            type: 'sparkplugDeviceMetrics',
        },
    };
    if (sparkplugDeviceMetricsChangeLog.changes.length) {
        ret.push(sparkplugDeviceMetricsChangeLog);
    }

    return ret;
};


export const undoAddition = (changeLogItem: ChangeLog.AddChangeLogData, extraData: AppletChangeLogExtraData) => {
    const newCfg = accessor.entityAsType('appletDesignVersion');
    if (!newCfg) {
        return;
    }
    if (extraData.type === 'screen') {
        const screenToRemove = newCfg.config.screens.find(s => s.id === changeLogItem.itemId);
        if (!screenToRemove) {
            throw new Error('Screen not found when undoing screen addition. Is the screen still in the config?');
        }
        accessor.appletDesign.removeScreen(screenToRemove);
    } else if (extraData.type === 'varps') {
        accessor.appletDesign.removeVarProv(changeLogItem.itemId);
        const varpCfg = newCfg.config.varProviders.find(vp => vp.id === changeLogItem.itemId);
        if (!varpCfg) {
            throw new Error('Varp not found when undoing varp addition. Is the varp still in the config?');
        }
        if (varpCfg.type === 'macroTarget') {
            const macroCell = accessor.appletDesign.findCellInCfg<Latest.Screen.Cells.MacroTargetCell>(c => c.type === 'macroTarget' && c.attrs.macroId === varpCfg.attrs.macroId);
            if (!macroCell) {
                throw new Error('Macro cell not found when undoing varp addition. Is the macro cell still in the config?');
            }
            accessor.appletDesign.deleteCell({
                cellCfg: macroCell.cellCfg,
                screenId: macroCell.screenId,
            });
        }
    } else if (extraData.type === 'macros') {
        const varpTarget = newCfg.config.varProviders.find(vp => vp.type === 'macroTarget' && vp.attrs.macroId === changeLogItem.itemId);
        if (!varpTarget) {
            throw new Error('Varp not found when undoing macro addition. Is the varp still in the config?');
        }
        accessor.appletDesign.removeVarProv(varpTarget.id);
        const macroTargetCell = accessor.appletDesign.findCellInCfg(c => c.type === 'macroTarget' && c.attrs.macroId === changeLogItem.itemId);
        if (!macroTargetCell) {
            throw new Error('Macro cell not found when undoing macro addition. Is the macro cell still in the config?');
        }
        accessor.appletDesign.deleteCell({
            screenId: macroTargetCell.screenId,
            cellCfg: macroTargetCell.cellCfg,
        });
    } else if (extraData.type === 'scripts') {
        const scriptOrigin = accessor.appletDesign.findScriptOrigin(changeLogItem.itemId, true);
        if (scriptOrigin) {
            if (scriptOrigin.type === 'cell') {
                // TODO: dont delete the button cell unless it is new. The log message for the script says "script was added to btn cell" so technically in that context we dont know if the button already existed
                accessor.appletDesign.deleteCell({
                    cellCfg: scriptOrigin.cell,
                    screenId: scriptOrigin.screenId,
                });
            } else if (scriptOrigin.type === 'varp') {
                accessor.appletDesign.removeVarProv(scriptOrigin.varp.id);
            }
        }
    } else if (extraData.type === 'sparkplugDeviceMetrics') {
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    sparkplugDeviceMetrics: newCfg.config.sparkplugDeviceMetrics.filter(sdm => sdm.name !== changeLogItem.itemId),
                },
            },
        });
    }
};

export const undoDeletion = (payload: TypedPreviousChangeLog) => {
    const newCfg = accessor.entityAsType('appletDesignVersion');
    if (!newCfg) {
        return;
    }

    if (payload.type === 'screen') {
        // when a screen is deleted it may also have deleted varps, macros, and scripts. Those have been stored and need to be restored
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    screens: [
                        ...newCfg.config.screens,
                        payload.changeLogItem.previousItem.screen,
                    ],
                    macros: [
                        ...newCfg.config.macros,
                        ...payload.changeLogItem.previousItem.macros,
                    ],
                    scripts: [
                        ...newCfg.config.scripts,
                        ...payload.changeLogItem.previousItem.scripts,
                    ],
                    varProviders: [
                        ...newCfg.config.varProviders,
                        ...payload.changeLogItem.previousItem.varps,
                    ],
                },
            },
        });
    } else if (payload.type === 'varps') {
        // when a varp is deleted it could also delete a script and/ or sparkplug metrics. Those have been stored and need to be restored
        if (payload.changeLogItem.previousItem.script) {
            newCfg.config.scripts = [
                ...newCfg.config.scripts,
                payload.changeLogItem.previousItem.script,
            ];
        }
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    varProviders: [
                        ...newCfg.config.varProviders,
                        payload.changeLogItem.previousItem.varp,
                    ],
                    sparkplugDeviceMetrics: [
                        ...newCfg.config.sparkplugDeviceMetrics,
                        ...payload.changeLogItem.previousItem.sparkplugMetrics,
                    ],
                },
            },
        });
    } else if (payload.type === 'macros') {
        // when a macro is deleted also deletes the corresponding cell and varp. The cell and varp has been stored and needs to be restored
        const newScreen = newCfg.config.screens.find(s => s.id === payload.changeLogItem.previousItem.cellData.screenId);
        if (!newScreen) {
            return;
        }
        newScreen.cells = [
            ...newScreen.cells,
            ...payload.changeLogItem.previousItem.cellData.cellCfgs.map(c => c.cellCfg),
        ];
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    macros: [
                        ...newCfg.config.macros,
                        payload.changeLogItem.previousItem.macroCfg,
                    ],
                    varProviders: [
                        ...newCfg.config.varProviders,
                        payload.changeLogItem.previousItem.varp,
                    ],
                    screens: [
                        ...newCfg.config.screens,
                    ],
                },
            },
        });
    } else if (payload.type === 'scripts') {

        // TODO: I dont think we should reset the script origin here. The undo button applies to the script value changing. The origin shouldnt reset as well. Display warning with this information

        // when a script is deleted also deletes the script origin which can either be a varp or cell. The origin has been stored and needs to be restored
        const scriptOrigin = payload.changeLogItem.previousItem.origin;
        if (scriptOrigin.type === 'cell') {
            const screen = newCfg.config.screens.find(s => s.id === scriptOrigin.screenId);
            if (!screen) {
                throw new Error('Screen not found when undoing script deletion. Is the screen still in the config?');
            }
            screen.cells = [
                ...screen.cells.filter(c => c.id !== scriptOrigin.cell.id),
                scriptOrigin.cell,
            ];
        } else if (scriptOrigin.type === 'varp') {
            newCfg.config.varProviders = [
                ...newCfg.config.varProviders.filter(vp => vp.id !== scriptOrigin.varp.id),
                scriptOrigin.varp,
            ];
        }
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    scripts: [
                        ...newCfg.config.scripts,
                        payload.changeLogItem.previousItem.scriptCfg,
                    ],
                },
            },
        });
    } else if (payload.type === 'sparkplugDeviceMetrics') {
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                    sparkplugDeviceMetrics: [
                        ...newCfg.config.sparkplugDeviceMetrics,
                        payload.changeLogItem.previousItem,
                    ],
                },
            },
        });
    }
};

export const undoUpdate = (payload: TypedPreviousChangeLog) => {
    const newCfg = accessor.entityAsType('appletDesignVersion');
    if (!newCfg) {
        return;
    }
    if (payload.type === 'screen') {
        const screenIdx = newCfg.config.screens.findIndex(s => s.id === payload.changeLogItem.itemId);
        if (screenIdx === -1) {
            throw new Error('Screen not found when undoing screen update. Is the screen still in the config?');
        }

        const screenThatIsBeingRemoved = newCfg.config.screens[screenIdx];
        screenThatIsBeingRemoved.cells.filter((c): c is Latest.Screen.Cells.Btn.Cell => c.type === 'btn').forEach(cell => {
            if (cell.attrs.linkedScriptId) {
                const scriptIdxInTheCfg = newCfg.config.scripts.findIndex(s => s.id === cell.attrs.linkedScriptId);
                if (scriptIdxInTheCfg === -1) {
                    throw new Error('Script not found when undoing screen update. Is the script still in the config?');
                }
                const originalScript = payload.changeLogItem.previousItem.scripts.find(s => s.id === cell.attrs.linkedScriptId);
                if (originalScript) {
                    newCfg.config.scripts.splice(scriptIdxInTheCfg, 1, originalScript);
                } else {
                    newCfg.config.scripts.splice(scriptIdxInTheCfg, 1);
                }
            }
        });
        screenThatIsBeingRemoved.cells.filter((c): c is Latest.Screen.Cells.MacroTargetCell => c.type === 'macroTarget').forEach(cell => {
            const macroIdxInTheCfg = newCfg.config.macros.findIndex(m => m.id === cell.attrs.macroId);
            if (macroIdxInTheCfg === -1) {
                throw new Error('Macro not found when undoing screen update. Is the macro still in the config?');
            }

            const originalMacro = payload.changeLogItem.previousItem.macros.find(m => m.id === cell.attrs.macroId);
            if (originalMacro) {
                newCfg.config.macros.splice(macroIdxInTheCfg, 1, originalMacro);
            } else {
                newCfg.config.macros.splice(macroIdxInTheCfg, 1);
            }

            const varpIdxInTheCfg = newCfg.config.varProviders.findIndex(vp => vp.type === 'macroTarget' && vp.attrs.macroId === cell.attrs.macroId);
            if (varpIdxInTheCfg === -1) {
                throw new Error('Varp not found when undoing screen update. Is the varp still in the config?');
            }

            const originalMacroVarp = payload.changeLogItem.previousItem.varps.find(vp => vp.type === 'macroTarget' && vp.attrs.macroId === cell.attrs.macroId);
            if (originalMacroVarp) {
                newCfg.config.varProviders.splice(varpIdxInTheCfg, 1, originalMacroVarp);
            } else {
                newCfg.config.varProviders.splice(varpIdxInTheCfg, 1);
            }
        });
        newCfg.config.screens.splice(screenIdx, 1, payload.changeLogItem.previousItem.screen);
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                },
            },
        });
    } else if (payload.type === 'varps') {
        const changeLogScript = payload.changeLogItem.previousItem.script;
        if (changeLogScript) {
            const existingScriptIdx = newCfg.config.scripts.findIndex(s => s.id === changeLogScript.id);
            newCfg.config.scripts.splice(existingScriptIdx, 1, changeLogScript);
        }
        const existingVarpIdx = newCfg.config.varProviders.findIndex(vp => vp.id === payload.changeLogItem.previousItem.varp.id);
        newCfg.config.varProviders.splice(existingVarpIdx, 1, payload.changeLogItem.previousItem.varp);
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                },
            },
        });
    } else if (payload.type === 'macros') {
        const newScreen = newCfg.config.screens[payload.changeLogItem.previousItem.cellData.screenIdx];
        for (const cellData of payload.changeLogItem.previousItem.cellData.cellCfgs) {
            newScreen.cells.splice(cellData.cellIdx, 1, cellData.cellCfg);
        }
        newCfg.config.screens.splice(payload.changeLogItem.previousItem.cellData.screenIdx, 1, newScreen);
        const macroIdx = newCfg.config.macros.findIndex(m => m.id === payload.changeLogItem.itemId);
        newCfg.config.macros.splice(macroIdx, 1, payload.changeLogItem.previousItem.macroCfg);
        const varpIdx = newCfg.config.varProviders.findIndex(vp => vp.id === payload.changeLogItem.previousItem.varp.id);
        newCfg.config.varProviders.splice(varpIdx, 1, payload.changeLogItem.previousItem.varp);
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                },
            },
        });
    } else if (payload.type === 'scripts') {
        newCfg.config.scripts = newCfg.config.scripts.map(s => {
            if (s.id === payload.changeLogItem.itemId) {
                return payload.changeLogItem.previousItem.scriptCfg;
            }
            return s;
        });
        // when a script is deleted also deletes the script origin which can either be a varp or cell. The origin has been stored and needs to be restored
        const scriptOrigin = payload.changeLogItem.previousItem.origin;
        if (scriptOrigin.type === 'cell') {
            const cellData = accessor.appletDesign.findCellInCfg(c => scriptOrigin.cell.id === c.id);
            if (!cellData) {
                throw new Error('Cell not found when undoing script deletion. Is the cell still in the config?');
            }
            cellData.screen.cells.splice(cellData.cellIdx, 1, scriptOrigin.cell);
            newCfg.config.screens.splice(cellData.screenIdx, 1, cellData.screen);
        } else if (scriptOrigin.type === 'varp') {
            const varpIdx = newCfg.config.varProviders.findIndex(vp => vp.id === scriptOrigin.varp.id);
            newCfg.config.varProviders.splice(varpIdx, 1, scriptOrigin.varp);
        }
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                },
            },
        });
    } else if (payload.type === 'sparkplugDeviceMetrics') {
        const sparkplugDeviceMetricsIdx = newCfg.config.sparkplugDeviceMetrics.findIndex(sdm => sdm.name === payload.changeLogItem.itemId);
        newCfg.config.sparkplugDeviceMetrics.splice(sparkplugDeviceMetricsIdx, 1, payload.changeLogItem.previousItem);
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...newCfg,
                config: {
                    ...newCfg.config,
                },
            },
        });
    }
};
