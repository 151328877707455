import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

type AppletEventDefSource = {
    kind: 'user';
} | {
    kind: 'btn';
    cellId: string;
};

/** centralized place to define how implied applet event ids are generated */
export const impliedAppletEventIds = {
    btnClick: (opts: { cellId: string }) => `${opts.cellId}:click`,
};

export type GetAppletEventsResult = Record<string, {
    def: Latest.AppletEvent.Def;
    source: AppletEventDefSource;
    // references: unknown[]; // FUTURE?
}>;
export function getImpliedAppletEvents (appletDesignVersionCfg: Latest.AppletDesignVersionConfig): GetAppletEventsResult {
    const result: GetAppletEventsResult = {};

    // implied events
    for (const screen of appletDesignVersionCfg.screens) {
        for (const cell of screen.cells) {
            if (cell.type === 'btn') {
                if (cell.attrs.emitAppletEvent || cell.attrs.varBehavior?.type === 'eventOnly') {
                    const appletEventId = impliedAppletEventIds.btnClick({ cellId: cell.id });
                    const appletEventName = cell.attrs.appletEventName ? cell.attrs.appletEventName : `${cell.name || 'Unnamed button'} clicked`;
                    result[appletEventId] = {
                        def: { appletEventId, appletEventName },
                        source: { kind: 'btn', cellId: cell.id },
                    };
                }
            }
        }
    }
    return result;
}
/**
 * @param appletDesignCfg The entire applet design config
 *
 * By making this function the source of truth of allowed applet events,
 * we ensure that all possible locations for applet events are touched here.
 * No need for zod schema hooks which are unreliably used.
 */
export function getAppletEvents (appletDesignVersionCfg: Latest.AppletDesignVersionConfig): GetAppletEventsResult {
    const result: GetAppletEventsResult = {};

    // user defined applet events
    for (const appletEventDef of Object.values(appletDesignVersionCfg.userAppletEvents)) {
        result[appletEventDef.appletEventId] = {
            def: appletEventDef,
            source: { kind: 'user' },
        };
    }
    const impliedEvents = getImpliedAppletEvents(appletDesignVersionCfg);
    for (const [ appletEventId, appletEvent ] of Object.entries(impliedEvents)) {
        result[appletEventId] = appletEvent;
    }


    return result;
}
