import { enMessages } from './en.i18n';
import { I18nResolver } from 'i18n-ts';

type ArgoSupportedLocale = 'en';

/**
 * all of the supported locales and their messages
 */
const resolverLocaleOptions: {
    [ lang in ArgoSupportedLocale ]: typeof enMessages;
} = {
    en: enMessages,
};

// browser locale is automatically determined by I18nResolver, and is available in `language`.
const i18nResolver = new I18nResolver({
    ...resolverLocaleOptions,
    default: enMessages,
}, 'en'); // 'en' is the preferredLanguage. it MUST be provided, or i18n-ts will try to use the browser's language... which is not available in nodejs.

/**
 * translations object, automatically configured for the user's locale
 */
export const messages = i18nResolver.translation;
export const language = i18nResolver.language;

/** datetime formats required for a locale */
export interface ArgoTimestampFormatters {
    /** simple date */
    date: Intl.DateTimeFormat;
    longDate: Intl.DateTimeFormat;
    /** simple time */
    time: Intl.DateTimeFormat;
    /** datetime, more verbose */
    long: Intl.DateTimeFormat;
    /** datetime, for simple things like created dates */
    short: Intl.DateTimeFormat;
    /** datetime, precise. ideal for transaction logs */
    ms: Intl.DateTimeFormat;
    /** datetime, precise. ideal for transaction logs with potentially different time zones */
    utc: Intl.DateTimeFormat;
}
