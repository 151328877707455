import { accessor } from 'src/store';
import type { UiFlowNode } from '../UiFlowNode';
import type {
    NodeCfg,
    NodeKind,
} from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';

/**
 * applies creating a socket from a node.
 *
 * This updates vuex, so update your node config before calling this.
 */
export function createNodeSocket<K extends NodeKind> (opts: {
    node: UiFlowNode<K>;
    newNodeCfg: NodeCfg<K>;
}): void {
    const {
        node,
        newNodeCfg,
    } = opts;

    const entityData = accessor.entityAsType('appletDesignVersion');
    if (!entityData) {
        return;
    }


    accessor.setPageEntity({
        entity: {
            ...entityData,
            config: {
                ...entityData.config,
                flows: {
                    ...entityData.config.flows,
                    [node.flowCfgRef.value.id]: {
                        ...node.flowCfgRef.value,
                        nodes: {
                            ...node.flowCfgRef.value.nodes,
                            [node.id]: {
                                ...newNodeCfg,
                            },
                        },
                    },
                },
            },
        },
        type: 'appletDesignVersion',
    });
}
