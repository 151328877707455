/* eslint-disable complexity */
/* eslint-disable max-lines */
import { v4 as uuidv4 } from 'uuid';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

const dcLocalInputVar = (cfgId: string, pdeId: string) => `${cfgId}:dcInput_${pdeId}_val`;

/**
 * computes the names of consts that can be either a var external to this scope,
 * or a var internal to this scope that needs a prefix
 */
function getConsts (cfg: Latest.Macros.Process.Macro) {
    const stateVars = cfg.attrs.stateVars;
    const validationErrorVar = stateVars.validationError || `${cfg.id}:validationErr`;
    const statusVar = stateVars.status || `${cfg.id}:state_status`;
    const reqVar = stateVars.required || `${cfg.id}:state_req`;
    const passVar = stateVars.good || `${cfg.id}:state_pass`;
    const failVar = stateVars.bad || `${cfg.id}:state_fail`;
    const specIdVar = stateVars.specId || `${cfg.id}:state_spec_id`;
    const specNameVar = stateVars.specName || `${cfg.id}:state_spec_name`;

    /** we need a var that will hold any errors from this macro */
    const aggErrVar = `${cfg.id}:aggErr`;
    const triggerVar = `${cfg.id}:trigger`;
    const btnVarName = `${cfg.id}:process_btn`;
    const runBtnEnabledVar = `${cfg.id}:runBtnEnabled`;
    const runProcessReportBtnVar = `${cfg.id}:runProcessReportBtn`;
    const runProcessReportVar = `${cfg.id}:runBtnProcessReport`;
    const matProcStateIdVar = `${cfg.id}:matProcStateId`;
    const matProcStateUpdateVar = `${cfg.id}:matProcStateUpdate`;

    return {
        validationErrorVar,
        statusVar,
        reqVar,
        passVar,
        failVar,
        specNameVar,
        specIdVar,
        aggErrVar,
        triggerVar,
        btnVarName,
        runBtnEnabledVar,
        runProcessReportBtnVar,
        runProcessReportVar,
        matProcStateIdVar,
        matProcStateUpdateVar,
    };
}

/**
 * Function to standardize how data collects are evalutated
 * @returns A conditional that evaluates to `true` when the given var is changed
 */
function generateDefaultConditional (varName: string): Latest.VarCondition {
    return {
        evalTrigger: 'varChange',
        boolOperation: 'and',
        comparisons: [
            {
                type: 'changed',
                okOnly: true,
                operands: [
                    {
                        type: 'var',
                        varName,
                    },
                ],
                not: false,
            },
        ],
    };
}

export const generateProcessMacroCells = (payload: {
    argoMacro: Latest.Macros.Process.Macro;
    linkedGridCell: Latest.Screen.Designer.RegularGridCell;
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    allArgoCells: Latest.Screen.Cells.AppletCell[];
    macroTargetCell: Latest.Screen.Cells.MacroTargetCell;
}): {
    argoCells: Latest.Screen.Cells.AppletCell[];
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
} => {
    const subGrid: Latest.Screen.Designer.SubGridCell = {
        name: '',
        rows: [],
        elevation: 0,
        type: 'subGrid',
        borderRadius: 0,
        columns: [ { weight: 1 } ],
        id: payload.linkedGridCell.id,
        margin: payload.linkedGridCell.margin,
        padding: payload.linkedGridCell.padding,
        coordinates: payload.linkedGridCell.coordinates,
        parentGridId: payload.linkedGridCell.parentGridId,
        backgroundColor: payload.linkedGridCell.backgroundColor,
        border: {
            top: { width: 1, color: 'black', style: 'none' },
            left: { width: 1, color: 'black', style: 'none' },
            right: { width: 1, color: 'black', style: 'none' },
            bottom: { width: 1, color: 'black', style: 'none' },
        },
    };
    payload.allLinkedGridCells = [
        ...payload.allLinkedGridCells.filter(lgc => lgc.id !== payload.linkedGridCell.id),
        subGrid,
    ];
    const {
        failVar,
        passVar,
        reqVar,
        statusVar,
        matProcStateIdVar,
        matProcStateUpdateVar,
        runBtnEnabledVar,
        runProcessReportBtnVar,
        // runProcessReportVar,
        specIdVar,
        btnVarName,
    } = getConsts(payload.argoMacro);


    if (payload.argoMacro.attrs.showMedia) {
        const processMediaCell: Latest.Screen.Cells.ProcessMediaCell = {
            type: 'processMedia',
            id: `${payload.argoMacro.id}_cell_processMedia`,
            attrs: {
                passVar,
                specIdVar,
            },
        };

        const processMediaCellLgc: Latest.Screen.Designer.RegularGridCell = {
            name: '',
            elevation: 0,
            id: uuidv4(),
            type: 'regular',
            borderRadius: 0,
            parentGridId: subGrid.id,
            coordinates: [ { column: 0, row: subGrid.rows.length } ],
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
            backgroundColor: {
                type: 'const',
                val: {
                    type: 'str',
                    status: 'ok',
                    val: '#00000000',
                },
            },
            border: {
                top: { width: 1, color: 'black', style: 'none' },
                left: { width: 1, color: 'black', style: 'none' },
                right: { width: 1, color: 'black', style: 'none' },
                bottom: { width: 1, color: 'black', style: 'none' },
            },
            additionalCellProperties: {
                argoCellId: processMediaCell.id,
            },
        };
        subGrid.rows.push({
            weight: 1,
        });
        payload.allArgoCells.push(processMediaCell);
        payload.allLinkedGridCells.push(processMediaCellLgc);
    }

    const processCell: Latest.Screen.Cells.ProcessCell = {
        id: `${payload.argoMacro.id}_cell_processcell`,
        type: 'process',
        attrs: {
            failedSteps: '',
            matId: '',
            passedSteps: '',
            processName: payload.argoMacro.name || 'Unnamed Process',
            specId: '',
            status: '',
            stepCount: '',
            showMedia: payload.argoMacro.attrs.showMedia || false,
            collectTriggerVar: '',
            matProcStateIdVar,
            currStatusVar: statusVar,
            matProcStateResponceVar: '',
            matProcStateAvailVar: '',
            refetchCondition: {
                evalTrigger: 'varChange',
                boolOperation: 'and',
                comparisons: [
                    {
                        not: false,
                        okOnly: true,
                        type: 'changed',
                        operands: [
                            {
                                type: 'var',
                                varName: matProcStateUpdateVar,
                            },
                        ],
                    },
                ],
            },
        },
        modifiers: {
            failedSteps: failVar,
            matId: matProcStateIdVar,
            passedSteps: passVar,
            matProcStateAvailVar: runProcessReportBtnVar,
            matProcStateResponceVar: matProcStateUpdateVar,
            // specId: payload.argoMacro.attrs., //TODO: update process provider to update this
            status: statusVar,
            stepCount: reqVar,
            // collectTriggerVar: payload.argoMacro.attrs.collectTrigger.type === 'btn' ? runBtnEnabledVar : '',
        },
    };

    const processCellLgc: Latest.Screen.Designer.RegularGridCell = {
        name: '',
        id: uuidv4(),
        elevation: 0,
        type: 'regular',
        borderRadius: 0,
        parentGridId: subGrid.id,
        margin: { top: 0, right: 0, bottom: 0, left: 0 },
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        coordinates: [ { column: 0, row: subGrid.rows.length } ],
        backgroundColor: {
            type: 'const',
            val: {
                type: 'str',
                status: 'ok',
                val: '#00000000',
            },
        },
        border: {
            top: { width: 1, color: 'black', style: 'none' },
            left: { width: 1, color: 'black', style: 'none' },
            right: { width: 1, color: 'black', style: 'none' },
            bottom: { width: 1, color: 'black', style: 'none' },
        },
        additionalCellProperties: {
            argoCellId: processCell.id,
        },
    };
    subGrid.rows.push({
        // TODO: Test with this
        // weight: payload.argoMacro.attrs.showMedia ? 0 : 1,
        weight: 1,
    });
    payload.allArgoCells.push(processCell);
    payload.allLinkedGridCells.push(processCellLgc);

    for (const dc of payload.argoMacro.attrs.dataCollection) {
        switch (dc.source) {
            case 'localInput': {
                // add bottom margin to the process cell
                processCellLgc.margin.bottom = 2;

                const inputCell: Latest.Screen.Cells.InputCell = {
                    id: `${payload.argoMacro.id}_dcInput_${dc.pdeId}`,
                    attrs: {
                        btnText: 'Submit',
                        captureFocus: false,
                        errorMsg: '',
                        label: dc.cellCfg.label,
                        trim: dc.cellCfg.trim,
                        writeVar: dcLocalInputVar(payload.argoMacro.id, dc.pdeId),
                        enabled: '',
                    },
                    modifiers: {
                        enabled: payload.argoMacro.attrs.matIdVar,
                    },
                    type: 'input',
                };
                const inputCellLgc: Latest.Screen.Designer.RegularGridCell = {
                    name: '',
                    id: uuidv4(),
                    elevation: 0,
                    borderRadius: 0,
                    type: 'regular',
                    parentGridId: subGrid.id,
                    margin: { top: 0, right: 3, bottom: 0, left: 3 },
                    padding: { top: 0, right: 0, bottom: 0, left: 0 },
                    coordinates: [ { column: 0, row: subGrid.rows.length } ],
                    backgroundColor: {
                        type: 'const',
                        val: {
                            type: 'str',
                            status: 'ok',
                            val: '#00000000',
                        },
                    },
                    border: {
                        top: { width: 1, color: 'black', style: 'none' },
                        left: { width: 1, color: 'black', style: 'none' },
                        right: { width: 1, color: 'black', style: 'none' },
                        bottom: { width: 1, color: 'black', style: 'none' },
                    },
                    additionalCellProperties: {
                        argoCellId: inputCell.id,
                    },
                };
                subGrid.rows.push({
                    weight: 1,
                });
                payload.allArgoCells.push(inputCell);
                payload.allLinkedGridCells.push(inputCellLgc);
                break;
            }
            case 'sparkplug':
            case 'var': {
                // noop
                break;
            }
            default: {
                // @ts-expect-error if this does not error, then a case is missing
                const unknownSource: string = dc.source;
                throw new Error(`Unknown data collection source "${unknownSource}"`);
            }
        }
    }

    if (payload.argoMacro.attrs.collectTrigger.type === 'btn') {
        const btnCell: Latest.Screen.Cells.Btn.Cell = {
            type: 'btn',
            id: `${payload.argoMacro.id}_cell_processStatus`,
            modifiers: {
                enabled: runBtnEnabledVar,
            },
            attrs: {
                size: 'large',
                label: `Run ${payload.argoMacro.name}`,
                color: 'green',
                enabled: '',
                targetVar: btnVarName,
                emitAppletEvent: false,
            },
        };
        const btnCellLgc: Latest.Screen.Designer.RegularGridCell = {
            name: '',
            id: uuidv4(),
            elevation: 0,
            type: 'regular',
            borderRadius: 0,
            parentGridId: subGrid.id,
            coordinates: [ { column: 0, row: subGrid.rows.length } ],
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            padding: { top: 0, right: 2, bottom: 2, left: 2 },
            backgroundColor: {
                type: 'const',
                val: {
                    type: 'str',
                    status: 'ok',
                    val: '#00000000',
                },
            },
            border: {
                top: { width: 1, color: 'black', style: 'none' },
                left: { width: 1, color: 'black', style: 'none' },
                right: { width: 1, color: 'black', style: 'none' },
                bottom: { width: 1, color: 'black', style: 'none' },
            },
            additionalCellProperties: {
                argoCellId: btnCell.id,
            },
        };
        subGrid.rows.push({
            weight: 1,
        });
        payload.allArgoCells.push(btnCell);
        payload.allLinkedGridCells.push(btnCellLgc);
    }

    return {
        argoCells: payload.allArgoCells,
        linkedGridCells: payload.allLinkedGridCells,
    };
};

export const generateProcessMacroVarps = (cfg: Latest.Macros.Process.Macro): Latest.VarProviders.VarProvider[] => {

    const {
        failVar,
        passVar,
        reqVar,
        specNameVar,
        specIdVar,
        statusVar,
        validationErrorVar,
        aggErrVar,
        btnVarName,
        matProcStateIdVar,
        matProcStateUpdateVar,
        runBtnEnabledVar,
        runProcessReportBtnVar,
        runProcessReportVar,
        triggerVar,
    } = getConsts(cfg);


    /**
     * Sparkplug device writers, keyed by the node cfg and device id
     */
    const sparkplugOutputs: Record<string, Latest.VarProviders.SparkplugDeviceWriteProvider> = {};

    const sparkplugInputs: Record<string, Latest.VarProviders.Sparkplug.Provider> = {};

    const localProvider: Latest.VarProviders.Local.Provider = {
        id: 'local',
        type: 'local',
        label: cfg.name,
        outputs: [ runProcessReportVar, matProcStateUpdateVar ],
        attrs: { vars: [
            {
                type: 'localVar',
                name: runProcessReportVar,
                varCfg: { type: 'num', val: 0, status: 'ok' },
            },
            {
                type: 'localVar',
                name: matProcStateUpdateVar,
                varCfg: { type: 'str', val: '', status: 'ok' },
            },
        ] },
    };

    /** format the errors from param writes */
    const formattedProviders: Latest.VarProviders.FormattedProvider[] = [];

    function generateSparkplugConsumerProvider (nodeCfg: Latest.VarProviders.Sparkplug.AppletNodeCfg): Latest.VarProviders.Sparkplug.Provider {
        const deviceKey = uuidv4();
        const spProvider: Latest.VarProviders.Sparkplug.Provider = {
            id: `sparkplug_provider_${deviceKey}`,
            attrs: {
                vars: [],
                nodeCfg,
            },
            outputs: [],
            label: `Data collect ${cfg.name}`,
            type: 'sparkplug',
            inputs: [],
        };
        sparkplugInputs[deviceKey] = spProvider;
        return spProvider;
    }

    const processProvider: Latest.VarProviders.ProcessProvider = {
        id: 'process',
        type: 'process',
        inputs: [ cfg.attrs.matIdVar, matProcStateUpdateVar ],
        outputs: [ validationErrorVar, statusVar, reqVar, passVar, failVar, specNameVar, specIdVar, matProcStateIdVar ],
        label: cfg.name,
        attrs: {
            trigger: {
                evalTrigger: 'varChange',
                boolOperation: 'and',
                comparisons: [],
            },
            dataCollectionVars: {},
            materialIdVar: cfg.attrs.matIdVar,
            materialProcessStateIdVar: matProcStateIdVar,
            materialProcessStateUpdateVar: matProcStateUpdateVar,
            params: {},
            processRevId: cfg.attrs.processRevId,
            location: cfg.attrs.processLocation,
            stateVars: {
                bad: failVar,
                good: passVar,
                required: reqVar,
                specId: specIdVar,
                specName: specNameVar,
                status: statusVar,
                validationError: validationErrorVar,
            },
        },
    };

    const varps: Latest.VarProviders.VarProvider[] = [];
    switch (cfg.attrs.collectTrigger.type) {
        /**
         * If the macro is already configured to use a conditional, then we can just use that
         */
        case 'conditional': {
            processProvider.attrs.trigger = cfg.attrs.collectTrigger.conditional;
            break;
        }
        /**
         * If the macro is configured to use a sparkplug device, then we need to add a trigger var as an output of the sparkplug provider
         *
         * Then create a conditional to watch when the trigger var is changed
         */
        case 'sparkplug': {
            const sparkplugProvider = generateSparkplugConsumerProvider(cfg.attrs.collectTrigger.varpCfg.nodeCfg === 'default' ? cfg.attrs.defaultSparkplugCfg : cfg.attrs.collectTrigger.varpCfg.nodeCfg);
            sparkplugProvider.outputs.push(triggerVar);
            sparkplugProvider.attrs.vars.push({
                ...cfg.attrs.collectTrigger.varpCfg,
                varName: triggerVar,
            });

            processProvider.inputs.push(triggerVar);
            processProvider.attrs.trigger = generateDefaultConditional(triggerVar);
            break;
        }
        /**
         * If the macro is configured to collect on variable change, then create a conditional to watch when the var is changed
         */
        case 'var': {
            processProvider.inputs.push(cfg.attrs.collectTrigger.var);

            processProvider.attrs.trigger = generateDefaultConditional(cfg.attrs.collectTrigger.var);
            break;
        }
        /**
         * If the macro is configured to collect on button press, then map the output of the button to the trigger var
         * and create a conditional that watches for changes to the trigger var
         *
         * NOTE: When a button is pressed it outputs the current timestamp
         */
        case 'btn': {
            localProvider.attrs.vars.push({
                name: btnVarName,
                type: 'localVar',
                varCfg: { type: 'num', val: 0, status: 'ok' },
            });
            localProvider.outputs.push(btnVarName);
            processProvider.inputs.push(btnVarName);
            processProvider.attrs.trigger = generateDefaultConditional(btnVarName);
            break;
        }
        default: {
            // @ts-expect-error if this does not error, then a case is missing
            const unknownTriggerType: string = cfg.attrs.collectTrigger.type;
            throw new Error(`Unknown collectTrigger type ${unknownTriggerType}`);
        }
    }

    for (const dc of cfg.attrs.dataCollection) {
        switch (dc.source) {
            case 'localInput': {
                // setup the input var to write to
                const inputVar = dcLocalInputVar(cfg.id, dc.pdeId);
                localProvider.attrs.vars.push({
                    name: inputVar,
                    type: 'localVar',
                    varCfg: { type: 'null', val: null, status: 'ok' },
                });
                localProvider.outputs.push(inputVar);

                // wire the input var to the process provider
                processProvider.attrs.dataCollectionVars[dc.pdeId] = inputVar;
                processProvider.inputs.push(inputVar);

                break;
            }
            case 'var': {
                if (dc.var) {
                    processProvider.attrs.dataCollectionVars[dc.pdeId] = dc.var;
                    processProvider.inputs.push(dc.var);
                }
                break;
            }
            case 'sparkplug': {
                const spVar = `dcSparkplug_${dc.pdeId}`;

                // setup the sparkplug var
                const sparkplugProvider = generateSparkplugConsumerProvider(dc.varpCfg.nodeCfg === 'default' ? cfg.attrs.defaultSparkplugCfg : dc.varpCfg.nodeCfg);
                sparkplugProvider.attrs.vars.push({
                    ...dc.varpCfg,
                    varName: spVar,
                });
                sparkplugProvider.outputs.push(spVar);

                // wire the sparkplug var to process provider
                processProvider.attrs.dataCollectionVars[dc.pdeId] = spVar;
                processProvider.inputs.push(spVar);
                break;
            }
            default: {
                // @ts-expect-error if this does not error, then a case is missing
                const unknownSource: string = dc.source;
                throw new Error(`Unknown data collection source "${unknownSource}"`);
            }
        }
    }

    for (const param of cfg.attrs.params) {
        switch (param.target) {
            case 'sparkplug': {
                const spWriteVar = `paramWriteSparkplug_${param.pdeId}`;

                // create the output from the process provider
                processProvider.outputs.push(spWriteVar);
                processProvider.attrs.params[param.pdeId] = spWriteVar;

                const sparkplugWriteId = uuidv4();
                // setup the sparkplug error local var
                const spErrorVar = `${sparkplugWriteId}_err`;

                // wire the error local var to a formatted provider
                const formatErrVar = `${sparkplugWriteId}_err_format`;
                formattedProviders.push({
                    id: `format_sparkplugWriteErr_${param.pdeId}`,
                    label: `Sparkplug error formatter ${cfg.name}`,
                    attrs: {
                        inputVars: [ spErrorVar ],
                        outputVar: formatErrVar,
                        stringTemplate: `Failed writing params to sparkplug device: {${spErrorVar}}`,
                        usingErrors: true,
                    },
                    outputs: [ formatErrVar ],
                    type: 'formatted',
                });

                const sparkplugCfg: Latest.VarProviders.SparkplugDeviceWriteProvider = {
                    id: sparkplugWriteId,
                    label: cfg.name,
                    attrs: {
                        errorVar: spErrorVar,
                        nodeCfg: param.varpCfg.nodeCfg === 'default' ? cfg.attrs.defaultSparkplugCfg : param.varpCfg.nodeCfg,
                        metrics: [],
                        varsToResetOnSend: [],
                        trigger: {},
                    },
                    outputs: [ spErrorVar ],
                    type: 'sparkplugDeviceWrite',
                    inputs: [ spWriteVar ],
                };
                sparkplugCfg.attrs.metrics.push({
                    metricExpr: param.varpCfg.metric,
                    varSource: spWriteVar,
                });
                sparkplugOutputs[sparkplugWriteId] = sparkplugCfg;
                break;
            }
            case 'var': {
                processProvider.outputs.push(param.var);
                processProvider.attrs.params[param.pdeId] = param.var;
                break;
            }
            default: {
                // @ts-expect-error if this does not error, then a case is missing
                const unknownTarget: string = param.target;
                throw new Error(`Unknown param target "${unknownTarget}"`);
            }
        }
    }


    for (const sparkplugDeviceKey in sparkplugInputs) {
        const sparkplugProvider = sparkplugInputs[sparkplugDeviceKey];
        if (sparkplugProvider && sparkplugProvider.outputs.length > 0) {
            varps.push(sparkplugProvider);
        }
    }
    if (localProvider.outputs.length > 0) {
        varps.push(localProvider);
    }
    varps.push(processProvider);
    for (const sparkplugOutputKey in sparkplugOutputs) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        varps.push(sparkplugOutputs[sparkplugOutputKey]!);
    }
    if (formattedProviders.length > 0) {
        varps.push(...formattedProviders);
    }

    /**
     * manages the state variables.
     *
     * state variables can be written to both user defined outputs, and macro generated outputs.
     *
     * the error variable can have multiple sources: any of the sparkplugOutput error outputs + process validation error
     */
    const comparativeProvider: Latest.VarProviders.Comparative.Provider = {
        id: 'coalesce_errs_and_state',
        label: `Coalesce errors and state (${cfg.name})`,
        attrs: {
            initialAsFallback: true,
            initialState: { type: 'null', val: null, status: 'ok' },
            targetVar: aggErrVar,
            assignments: [],
        },
        type: 'comparative',
        inputs: [],
        outputs: [
            aggErrVar,
        ],
    };

    // we need to aggregate the error vars
    comparativeProvider.attrs.assignments.push({
        evalTrigger: 'varChange',
        boolOperation: 'and',
        comparisons: [ { type: 'isset', not: false, operands: [ { type: 'var', varName: validationErrorVar } ] } ],
        inputType: 'var',
        inputVar: validationErrorVar,
    });
    comparativeProvider.inputs.push(validationErrorVar);
    for (const fp of formattedProviders) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sparkplugWriteErrVar = fp.attrs.inputVars[0]!;
        comparativeProvider.attrs.assignments.push({
            evalTrigger: 'varChange',
            boolOperation: 'and',
            comparisons: [ { type: 'isset', not: false, operands: [ { type: 'var', varName: sparkplugWriteErrVar } ] } ],
            inputType: 'var',
            inputVar: fp.attrs.outputVar,
        });
        comparativeProvider.inputs.push(sparkplugWriteErrVar, fp.attrs.outputVar);
    }

    // create a variable for enabling the button
    const comparativeProviderRunProcessBtn: Latest.VarProviders.Comparative.Provider = {
        id: 'comparative_provider_run_process_btn',
        label: `Activate and deactivate process run button (${cfg.name})`,
        attrs: {
            initialAsFallback: true,
            initialState: { type: 'null', val: null, status: 'ok' },
            targetVar: runBtnEnabledVar,
            assignments: [
                {
                    evalTrigger: 'varChange',
                    boolOperation: 'and',
                    comparisons: [
                        { type: 'isset', not: false, operands: [ { type: 'var', varName: cfg.attrs.matIdVar } ] },
                        {
                            type: 'eq',
                            not: false,
                            operands: [
                                { type: 'var', varName: statusVar },
                                { type: 'str', val: 'incomplete' },
                            ],
                        },
                    ],
                    inputType: 'constNum',
                    inputVal: 1,
                },
            ],
        },
        type: 'comparative',
        inputs: [ cfg.attrs.matIdVar, statusVar ],
        outputs: [ runBtnEnabledVar ],
    };

    // create a variable for enabling the status report button
    const comparativeProviderRunProcessStatusReportBtn: Latest.VarProviders.Comparative.Provider = {
        id: 'comparative_provider_run_process_status_report_btn',
        label: `Activate and deactivate process run material report button (${cfg.name})`,
        attrs: {
            initialAsFallback: true,
            initialState: { type: 'null', val: null, status: 'ok' },
            targetVar: runProcessReportBtnVar,
            assignments: [
                {
                    evalTrigger: 'varChange',
                    boolOperation: 'and',
                    comparisons: [
                        { type: 'isset', not: false, operands: [ { type: 'var', varName: cfg.attrs.matIdVar } ] },
                    ],
                    inputType: 'constNum',
                    inputVal: 1,
                },
            ],
        },
        type: 'comparative',
        inputs: [ cfg.attrs.matIdVar ],
        outputs: [ runProcessReportBtnVar ],
    };

    varps.push(comparativeProvider);
    varps.push(comparativeProviderRunProcessStatusReportBtn);
    varps.push(comparativeProviderRunProcessBtn);

    return varps;
};
