import z from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';

const locationSchemaBase = z.object({
    id: z.string().uuid(),
});

const locationDeployParamSchema = deployParamSchema(z.object({
    deployParamId: z.string(),
    deployParamKind: z.literal(DeployParamKind.location),
}));
export interface LocationDeployParam extends z.infer<typeof locationDeployParamSchema> {}

export const locationSchema = z.union([ locationDeployParamSchema, locationSchemaBase.strict() ]);

export type LocationCfg = z.infer<typeof locationSchema>;
