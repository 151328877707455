import {
    ClassicPreset,
} from 'rete';
import type { UiFlowNode } from '../UiFlowNode';
import type { NodeKind } from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';
import { ModelNumberFragment } from 'types/db';

type UiFlowNodeControlBaseConfig = {
    nullable?: boolean;
    label?: string;
    /**
     * The flag for optional determines styling for the label using italics for optional fields
     *
     * In order to get the same styling on the labels for the sockets we need to make out own socket components which I think is in scope eventually
     * NOTE: We _could_ make a custom label component and set the default socket text to an empty string, but I feel like that is a bandaid
     */
    optional?: boolean;
}

export type SelectionItem = {
    label: string;
    value: string | number;
};

type SelectionControlCfg = UiFlowNodeControlBaseConfig & {
    kind: 'selection';
    placeholder?: string;
    items: SelectionItem[] | (() => SelectionItem[]);
};

export type MaterialModelIdControlCfg = UiFlowNodeControlBaseConfig & {
    placeholder?: string;
    kind: 'createMaterial.modelId';
    onSelect: (materialModel: ModelNumberFragment) => void;
};

type UiFlowNodeControlConfigMap = {
    number: UiFlowNodeControlBaseConfig & { kind: 'number', min?: number; max?: number };
    text: UiFlowNodeControlBaseConfig & { kind: 'text', placeholder?: string };
    boolean: UiFlowNodeControlBaseConfig & { kind: 'boolean' };
    button: UiFlowNodeControlBaseConfig & { kind: 'button', label: string, onClick: (control: UiFlowNodeControl<unknown>) => void };
    deletableSocket: UiFlowNodeControlBaseConfig & { kind: 'deletableSocket', label: string, onDelete: () => void };
    selectVar: UiFlowNodeControlBaseConfig & {
        kind: 'selectVar',
        /** whether to include variables from varps */
        allVars?: boolean,
    };
    selectEvent: UiFlowNodeControlBaseConfig & { kind: 'selectEvent' };
    comparisonOperator: UiFlowNodeControlBaseConfig & { kind: 'comparisonOperator' };
    'jsonParse.outputExpression': UiFlowNodeControlBaseConfig & { kind: 'jsonParse.outputExpression', placeholder?: string, onDelete: () => void };
    selection: SelectionControlCfg;
    scriptSelect: UiFlowNodeControlBaseConfig & { kind: 'scriptSelect' };
    'sparkplug.metricExpression': UiFlowNodeControlBaseConfig & { kind: 'sparkplug.metricExpression', onDelete: () => void };
    'createMaterial.attributes': UiFlowNodeControlBaseConfig & { kind: 'createMaterial.attributes', placeholder?: string; };
    location: UiFlowNodeControlBaseConfig & { kind: 'location' };
    'createMaterial.modelId': MaterialModelIdControlCfg;
}
export type UiFlowNodeControlConfig = UiFlowNodeControlConfigMap[keyof UiFlowNodeControlConfigMap];

export class UiFlowNodeControl<T> extends ClassicPreset.Control {
    /**
     * defines how the user interacts with the control,
     * what `kind` of component to render,
     * and other props the component will want
     */
    cfg: UiFlowNodeControlConfig;
    value: T;
    disabled: boolean = false;
    node: UiFlowNode<NodeKind>;
    /**
     * affects sorting
     */
    index?: number;

    /** for the node to capture changes */
    changeCb?: (value: T) => void;

    constructor (opts: {
        cfg: UiFlowNodeControlConfig,
        value: T,
        nullable?: boolean,
        node: UiFlowNode<NodeKind>,
        index?: number,
    }) {
        super();
        this.cfg = opts.cfg;
        this.value = opts.value;
        this.disabled = opts.node.disabled ?? false;
        this.cfg.nullable = opts.nullable ?? false;
        this.node = opts.node;
        this.index = opts.index;
    }

    // expected by rete
    setValue (value: T): void {
        this.value = value;
        if (this.changeCb) {
            this.changeCb(value);
        }
    }
}
